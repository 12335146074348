import BootstrapModal from 'bootstrap/js/src/modal';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'confirmButton',
    'confirmModal',
    'confirmModalBody',
    'customerInformationInput',
    'customerRequestInput',
    'errorTextTemplate',
    'modalBodyTemplate',
    'modalRowTemplate',
  ]

  static values = {
    busTypesTranslateList: Object,
    usePurposesTranslateList: Object,
  }

  connect() {
    // console.log('contactable')
  }

/*
 *  表示関連
 */

  checkAgreement(event) {
    const confirmButton = this.confirmButtonTarget;
    if(event.currentTarget.dataset.agreement === 'yes'){
      confirmButton.classList.add('n-is-active');
    } else {
      confirmButton.classList.remove('n-is-active');
    }
  }

  preventSubmit(event){
    event.preventDefault()
  }

  confirm(event) {
    event.preventDefault()

    if (!this.validate()) return;

    const modal = new BootstrapModal(this.confirmModalTarget)
    modal.show()

    this.setModalBody()
  }

  setModalBody(){
    const customerInformationListDom = []
    this.customerInformationInputTargets.forEach(input => {
      if(input.name.includes('email_confirm')) return

      customerInformationListDom.push(this.createModalRowDom(input.dataset.label, input.value))
    })

    const customerRequestListDom =
      this.createcustomerRequestListDom(this.rebuildInputsToObject(this.customerRequestInputTargets))

    const modalBody =
      this.modalBodyTemplateTarget.innerHTML
        .replace('CUSTOMER_INFORMATION_LIST', customerInformationListDom.join(''))
        .replace('CUSTOMER_REQUEST_LIST', customerRequestListDom.join(''))
    this.confirmModalBodyTarget.innerHTML = modalBody
  }

  createModalRowDom(key, value){
    return this.modalRowTemplateTarget.innerHTML
            .replace('KEY', key)
            .replace('VALUE', value)
  }

  rebuildInputsToObject(inputList){
    const inputs = {}
    inputList.forEach(input => {
      if(input.type === 'checkbox'){
        if(input.checked === false) return;

        // checkbox項目を「、」で分けるために配列にする
        if(inputs[input.dataset.label] === undefined){
          inputs[input.dataset.label] = []
        }

        inputs[input.dataset.label].push(input.value)
        return
      }

      if(input.type === 'select-one' && input.value === '') return

      inputs[input.dataset.label] = input.value
    });
    return inputs
  }

  createcustomerRequestListDom(inputs){
    const customerRequestListDom = []
    for(const key in inputs){
      let value = inputs[key]

      switch(key){
        case '利用目的':
          value = this.translateWordsToJa(value, 'usePurposes')
          break

        case 'ご希望のバスタイプ':
          value = this.busTypesTranslateListValue[value]
          break

        default:
      }

      if(Array.isArray(value)){
        value = value.join('、')
      }

      customerRequestListDom.push(this.createModalRowDom(key, value))
    }
    return customerRequestListDom
  }

  translateWordsToJa(english_words, type){
    const translated_words = []

    switch(type){
      case 'usePurposes':
        english_words.forEach(word => {
          translated_words.push(this.usePurposesTranslateListValue[word])
        })
        break

      default:
    }

    return translated_words
  }

  validate(){
    const inputs = [
      ...this.customerInformationInputTargets,
      ...this.customerRequestInputTargets];
    let valid = true;

    for (let i = 0; i < inputs.length; i++) {
      valid = this.checkInput(inputs[i]) && valid;
    }

    return valid;
  }

  // input.type別の関数
  checkInput(input){

    // 全体で使うメッセージ
    const errorMessages = {
      empty: '必須の項目を入力してください。',
      format: 'Email の形式を入力してください。',
      emailPair: 'メールアドレスが一致しません。',
    };

    const nextElem = input.nextElementSibling;
    if (nextElem === null) return true;

    nextElem.innerHTML = '';
    nextElem.classList.remove('d-block');

    const errors = [];
    if (!this.validRequired(input)) errors.push(errorMessages['empty']);

    if (input.type === 'email' && !this.validEmailFormat(input) && input.value !== '')
      errors.push(errorMessages['format']);

    if (!this.validEmailPair(input)) errors.push(errorMessages['emailPair']);

    if (errors.length > 0) {
      nextElem.innerHTML = errors.join('<br>');
      nextElem.classList.add('d-block');
      return false;
    }

    return true;
  };

  // バリデーション
  validRequired(element) {
    if (element.required && element.value === '') return false;

    return true;
  };

  validEmailFormat(element){
    if (!element.value.includes('@')) return false;

    return true;
  };

  validEmailPair(element){
    if (
      element.classList.contains('js-email-confirm') &&
      element.value !==
        document.querySelector('.js-email').value
    ) {
      return false;
    }

    return true;
  };
}