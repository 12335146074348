import Swiper from 'swiper/swiper-bundle';
export const initializeSwiper = () => {

  const homeMainVisualSwiper = new Swiper(document.querySelector<HTMLDivElement>('.js-home-main-visual-slider'), {

    loop: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    direction: 'horizontal',
    effect: 'fade',
    pagination: {
      el: ".swiper-pagination",
    },

  })

  const busIntroductionSwiper = new Swiper(document.querySelector<HTMLDivElement>('.js-introduction-slider'), {

    direction: 'horizontal',
    loop: true,
    allowTouchMove: false,
    disableOnInteraction: false,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },

    scrollbar: {
      el: '.swiper-scrollbar',
    },
  })

  const busliftSwiper = new Swiper(document.querySelector<HTMLDivElement>('.js-bus-lift-slider'), {

    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    effect: 'fade',
    
  })

  const sliderWithThumbnails = document.querySelectorAll<HTMLDivElement>('.js-slider-with-thumbnails');

  sliderWithThumbnails.forEach((sliderBox) => {
    const busDetailThumbnails = new Swiper(sliderBox.querySelector<HTMLDivElement>('.js-bus-detail-thumbnails'), {
      slidesPerView: 8,
      freeMode: true,
    })
    const busDetailsSwiper = new Swiper(sliderBox.querySelector<HTMLDivElement>('.js-bus-details-slider'), {
      direction: 'horizontal',
      loop: true,
      thumbs: {
        swiper: busDetailThumbnails
      }
    })
  })
};