import { headerNavigation } from './src/header_navigation';
import { initializeSwiper } from './src/swiper'
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

initializeSwiper();
headerNavigation();

const application = Application.start()
const context = require.context("./controllers", true, /\.ts$/)
application.load(definitionsFromContext(context))

window.addEventListener("load", () => {
  const tamatabi_contact_form = document.querySelector("#tamatabi-contact-form");
  if (tamatabi_contact_form) {
    tamatabi_contact_form.addEventListener("ajax:success", () => {
      alert("お問合せ送信しました。")
    });
    tamatabi_contact_form.addEventListener("ajax:error", () => {
      alert("お問合せ送信できませんでした。再度お試してください。")
    });
  }
  const newprince_contact_form = document.querySelector("#newprince-contact-form");
  if (newprince_contact_form) {
    newprince_contact_form.addEventListener("ajax:success", () => {
      alert("お問合せ送信しました。")
    });
    newprince_contact_form.addEventListener("ajax:error", () => {
      alert("お問合せ送信できませんでした。再度お試してください。")
    });
  }
});
